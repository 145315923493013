/* 출력할 때 사용*/
/* https://developer.mozilla.org/en-US/docs/Web/CSS/@page */
@page{
    margin: 1cm;
}


.tableWrap{
    padding: 10px 10px;
    height: 100%;
    /* max-height: 1020px; */
    /* position: relative; */
}
.viewerContainer {
    width: 100%;
    /* padding: 0 10px;
    box-sizing: border-box; */
}
.viewerContainer table {
    width: 100%;
    font-size: 12px;
    border-collapse : collapse;
}
.viewerContainer table th,
.viewerContainer table td {
    min-width: 20px;
    text-align: center;
    border: 1px solid #000;
}
.apiTable {
    margin-bottom: 10px;
}
.apiTable th {
    background-color: #eee;
}
.viewerImg {
    width: 100%;
}

.sign{
    border:1px solid #ccc;
}
.sign tr td{
    border:none;
}

.bgnone{
    background-color: none;
}


/* 지원신청서 */
.tableWrap.apply .viewerContainer.bd0 table tr th{
    background-color : transparent;
    padding : 5px 0;
}

.tableWrap.apply .viewerContainer table th,
.tableWrap.apply .viewerContainer table td {
    border: 1px solid #000;
}


.tableWrap.apply .applySign{
    border: 1px solid #000;
    border-top:0;
    padding: 5px 15%;
    text-align: center;
}
.tableWrap.apply .applySign .txt, .day{
    letter-spacing: 2px;
    font-size: 12px;
}

.tableWrap.apply .applySign .signBox{
    border:0;
    margin-bottom:10px;
    margin: 5px 50px;
}
.tableWrap.apply .applySign .signBox > div{
    display: inline-block;
    font-size: 12px;
}
.tableWrap.apply .applySign .signBox .title{
    text-align: left;
    width: 25%;
}
.tableWrap.apply .applySign .signBox .title > div:first-child{
    letter-spacing: 12px;
}
.tableWrap.apply .applySign .signBox .name{
    text-align: center;
    width: 50%;
}
.tableWrap.apply .applySign .signBox .sign{
    text-align: right;
    width: 25%;;
    border : 0;
}

.tableWrap.apply .applySign >div:last-child{
    font-size: 13px;
    font-weight: bold;
}




/* 권리분석 검토서 */
.tableWrap .viewerContainer.borderwrap{
    border: 1px solid #000;
    height: 100%;
}
.tableWrap .viewerContainer table tbody tr th{
    background-color: #ddd;
    padding : 5px 0;
    font-weight: normal;
}
.tableWrap.review .viewerContainer table th,
.tableWrap.review .viewerContainer table td {
    border: 1px solid #000;
}
.tableWrap.review .viewerContainer table tbody tr:first-child td:first-child{
    border:0;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}
.tableWrap.review .viewerContainer table tbody tr:first-child td{
    font-weight: normal;
}

.tableWrap.review .viewerContainer table td span{
    float: right;
}
.tableWrap.review .viewerContainer table td span::before{
    content: '';
    padding-left: 5px;
}
.tableWrap.review .viewerContainer table td span::after{
    content: '';
    padding-right: 5px;
}
.tableWrap .viewerContainer table tbody tr td{
    padding : 5px 0;
}
.tableWrap.review .viewerContainer table tbody tr td{
    padding : 0
}

.review .viewerContainer table{
    margin-bottom: 10px
}
.review .viewerContainer table:last-child{
    margin-bottom: 0;
}
.review .viewerContainer .reviewSign{
    text-align: center;
    margin-top: 10px;
    font-size: 13px;
}
.review .viewerContainer .reviewSign .txt{
    margin-bottom: 5px;
}
.review .viewerContainer .reviewSign .sign{
    margin-top: 10px;
    border : none;
    display: flex;
    justify-content: center;
    justify-items: baseline;
    margin-bottom: 10px;
}
.review .viewerContainer .reviewSign .sign>div:first-child{
    font-size: 15px;
}
.review .viewerContainer .reviewSign .sign>div:nth-child(2){
    margin-left: 100px;
    letter-spacing: 3px;
}
.review .viewerContainer .reviewSign .sign>div:nth-child(3){
    margin-left: 100px;
    letter-spacing: 3px;
}
.tableWrap.review .viewerContainer table tbody tr th:first-child{
    border-left:0
}
.tableWrap.review .viewerContainer table tbody tr td:last-child{
    border-right: 0;
}
.tableWrap.review .viewerContainer .secureReview th{
    text-align: left;
}
.tableWrap.review .viewerContainer .area span{
    /* white-space: nowrap;
    overflow: hidden; */
    word-break: break-all;
    float:none;
    display: inline-block;
}
/* .tableWrap.review .viewerContainer .area .areaDetail{
    width:90px
} */


.tableWrap.review .viewerContainer .area span::before{
    content: '';
    padding-left: 0;
}
.tableWrap.review .viewerContainer .area span::right{
    content: '';
    padding-right: 0;
}

.tableWrap.review .viewerContainer .current{
    table-layout: fixed;
}
.tableWrap.review .viewerContainer table tbody tr th{
    padding: 3px 0
}


/* 집합건축물 대장 */
.tableWrap.all.build .viewerContainer tr th{
    font-weight: normal;
}

.tableWrap.all.build .viewerContainer th,
.tableWrap.all.build .viewerContainer td{
    padding: 5px 0 
}

.tableWrap.all.build .viewerContainer tr th:first-child{
    border-left:0
}
.tableWrap.all.build .viewerContainer tr th:last-child{
    border-right: 0;
}

.tableWrap.all.build .viewerContainer tr td:last-child{
    border-right: 0;
}
.tableWrap.all.build .viewerContainer tbody tr td:first-child{
    border-left:0
}

.tableWrap.all.build .viewerContainer table th,
.tableWrap.all.build .viewerContainer table td {
    border: 1px solid #000;
}



/* 일반건축물 대장 */
.tableWrap.build .viewerContainer .bulidNum{
    width:100%;
}
.tableWrap.build .viewerContainer .bulidType{
    width:100%;
}

.tableWrap.build .viewerContainer table th,
.tableWrap.build .viewerContainer table td{
    font-size: 11px;
    background-color: transparent;
    height: 10px;
}
.tableWrap.build .viewerContainer .bulidNum th,
.tableWrap.build .viewerContainer .bulidType th{
    vertical-align : top;
    text-align : left;
    padding : 0 5px;
    border-right:0;
    font-weight:normal
}
.tableWrap.build .viewerContainer .bulidNum td,
.tableWrap.build .viewerContainer .bulidType td{
    border-left:0;
}
.tableWrap.build .viewerContainer .bulidNum tr th:first-child,
.tableWrap.build .viewerContainer .bulidType tr th:first-child{
    border-left: 0;
}
.tableWrap.build .viewerContainer .bulidNum tr td:last-child,
.tableWrap.build .viewerContainer .bulidType tr td:last-child{
    border-right: 0;
}

.tableWrap.build .viewerContainer .bulidNum td,
.tableWrap.build .viewerContainer .bulidType td{
    vertical-align : bottom;
    text-align : right;
    padding : 0 5px;
}

.tableWrap.build .viewerContainer .bulidType tr:nth-child(n+3) th {
    border-bottom:0
}
.tableWrap.build .viewerContainer .bulidType tr:nth-child(n+3) td{
    border-top:0
}

.tableWrap.build .viewerContainer .bulidReport tr th:first-child,
.tableWrap.build .viewerContainer .bulidReport tr td:first-child{
    border-left:0
}

.tableWrap.build .viewerContainer .bulidReport thead tr th{
    padding : 5px 0;
    font-weight:normal
}
.tableWrap.build .viewerContainer .bulidReport thead tr:first-child th,
.tableWrap.build .viewerContainer .bulidReport thead tr:last-child th,
.tableWrap.build .viewerContainer .bulidReport tbody tr td:last-child{
    border-right:0
}

.tableWrap.build .viewerContainer .ownerReport tr th:first-child,
.tableWrap.build .viewerContainer .ownerReport tr td:first-child{
    border-left:0
}

.tableWrap.build .viewerContainer .ownerReport thead tr th{
    padding : 5px 0;
    font-weight:normal
}
.tableWrap.build .viewerContainer .ownerReport thead tr:first-child th,
.tableWrap.build .viewerContainer .ownerReport thead tr:last-child th,
.tableWrap.build .viewerContainer .ownerReport tbody tr td:last-child{
    border-right:0
}

.tableWrap.build .viewerContainer .change tr th:first-child,
.tableWrap.build .viewerContainer .change tr td:first-child{
    border-left:0
}

.tableWrap.build .viewerContainer .change thead tr th{
    padding : 5px 0;
    font-weight:normal
}
.tableWrap.build .viewerContainer .change thead tr:first-child th,
.tableWrap.build .viewerContainer .change thead tr:last-child th,
.tableWrap.build .viewerContainer .change tbody tr td:last-child{
    border-right:0
}
.tableWrap.build .viewerContainer .etc tr th:first-child,
.tableWrap.build .viewerContainer .etc tr td:first-child{
    border-left:0
}

.tableWrap.build .viewerContainer .etc thead tr th{
    padding : 5px 0;
    font-weight:normal
}
.tableWrap.build .viewerContainer .etc thead tr:first-child th,
.tableWrap.build .viewerContainer .etc thead tr:last-child th,
.tableWrap.build .viewerContainer .etc tbody tr td:last-child{
    border-right:0
}

.tableWrap.build .viewerContainer table th,
.tableWrap.build .viewerContainer table td {
    border: 1px solid #000;
}



/* 주택가격정보 - 개별 */
.tableWrap.price .viewerContainer .titleOpen{
    width:100%;
    padding: 5px 0;
    border:1px solid #DDB195;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom:5px;
    color:#DDB195;
    font-size: 13px;
}

.tableWrap.price .viewerContainer .titleAd{
    display: flex;
    margin-bottom: 5px;
    text-align: center;
    font-size: 12px;
    
}
.tableWrap.price .viewerContainer .titleAd>div:first-child{
    width: 20%;
    border:1px solid #DDB195;
    border-right: 0;
    padding : 5px 0;
    color: #DDB195;
}
.tableWrap.price .viewerContainer .titleAd>div:last-child{
    width: 80%;
    border:1px solid #DDB195;
    padding : 5px 0;
}

.tableWrap.price .viewerContainer table th{
    background-color: transparent;
}
.tableWrap.price .viewerContainer table th,
.tableWrap.price .viewerContainer table td{
    border : 1px solid #DDB195;
    padding : 5px 0
}
.tableWrap.price .viewerContainer table th{
    color: #DDB195;
    font-weight: normal;
}
.tableWrap.price .viewerContainer .exp1{
    font-size: 12px;
    margin-top:-5px
}
.tableWrap.price .viewerContainer .exp2{
    display: flex;
    border: 1px solid #000;
    height: auto;
    padding: 10px 5px ;

}
.tableWrap.price .viewerContainer .exp2 div:first-child{
    width: 30%;
    font-size: 12px;
}
.tableWrap.price .viewerContainer .exp2 div:last-child{
    width: 70%;
    font-size: 12px;
}


/* 주택가격정보 - 공통 */
.tableWrap.price.all h2{
    text-align: center;
    margin-bottom: 30px;
}
.tableWrap.price.all .viewerContainer .address{
    display: flex;
    margin-bottom: 10px;
}
.tableWrap.price.all .viewerContainer .address div:first-child{
    color:#38167E;
    min-width:90px
}
.tableWrap.price.all .viewerContainer .address div:last-child{
    padding-left: 25px;
}
.tableWrap.price.all .viewerContainer table{
    border : 2px solid #8DADD5
}

.tableWrap.price.all .viewerContainer table th{
    background-color: transparent;
    color:#38167E
}

.tableWrap.price.all .viewerContainer table th,
.tableWrap.price.all .viewerContainer table td{
    border : 1px solid #ccc;
    padding : 5px 0;
}

.tableWrap.price.all .openName{
    margin-top:15px;
    color:#38167E;
    margin-bottom:10px;
}

.tableWrap.price.all .useType{
    text-align: center;
    border: 2px solid #8DADD5;
}
.tableWrap.price.all .useType div{
    padding : 5px 0
}
.tableWrap.price.all .useType div:first-child{
    border-bottom: 1px solid #8DADD5;
}
.tableWrap.price.all .precaWrap{
    margin-top:30px;
    text-align: center;
    position: relative;
}

.tableWrap.price.all .precaWrap div:first-child::before{
    content: '<';
    width: 20px;
    margin-right: 10px;
}

.tableWrap.price.all .precaWrap div:first-child{
    position: absolute;
    width: 100px;
    background-color: #fff;
    left: 50%;
    -webkit-transform: translate3d(0,0,0) translateZ(1);
    transform: translate3d(0,0,0) translateZ(1);
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.tableWrap.price.all .precaWrap div:first-child::after{
    content: '>';
    width: 20px;
    margin-left: 10px;
}


.tableWrap.price.all .precaWrap .preca{
    border: 1px solid #ccc;
    font-size: 13px;    
}


/* 신/구 주소 */
/* .tableWrap.address .viewerContainer.borderwrap{
    
    
} */

.tableWrap.address .viewerContainer .title{
    font-size: 20px;
    padding-left: 30px;
    
    
}
.tableWrap.address .viewerContainer .title span{
    display: block;
    font-weight: bold;
    font-size: 25px;
    padding: 15px 5px;
    box-sizing: border-box;
}

.tableWrap.address .viewerContainer .adTable{
    margin-top:50px;
    border : 1px solid #000;
    
    border-radius: 5px;
    margin : 50px 30px;
}

.tableWrap.address .viewerContainer .adTable div{
    padding: 10px 10px;
    box-sizing: border-box;
    border-bottom:1px solid #000
}
.tableWrap.address .viewerContainer .adTable div:last-child{
    border-bottom: none;
}

.tableWrap.address .viewerContainer .adTable span{
    font-weight: bold;
}



/* 피드백 */
/* .prtpopupModal > div:nth-child(2){
    width: 1300px !important;
    padding: 30px 40px;
    overflow-y: auto;
} */
.tableWrap.feedback .viewerContainer input{
    /* width: 100%; */
    border: none;
    text-align: center;
}

.tableWrap.feedback .focusY{
    background-color: yellow !important;
}
.tableWrap.feedback .focusO{
    background-color: orange !important;
}
.divButtons {
    display: flex;
    justify-content: center;
}
.divButtons button{
    margin: 0 10px;
}

.tableWrap.feedback .feedInfo {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.tableWrap.feedback .feedInfo .info-box {
    color: #545454;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    margin: 3px;
}
.feedInfo .info-box > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.feedInfo .info-box > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.tableWrap.feedback .feedInfo .info-box div:first-child {
    height: 20px;
    font-size: 13px;
    background-color: #ccc;
}
.info-box div:last-child {
    font-size: 12px;
    padding: 2px 0;
    display: flex;
}
.info-box input[type=text] {
    width: 100%;
}
.info-box input[type=date] {
    width: 145px;
    height: 27px;
}
.info-box span {
    font-size: 12px;
}
.feedInfo .info-box .rbtn {
    padding-top: 6px;
}
.feedInfo .info-box .remark {
    padding: 0;
}
.tableWrap.feedback .feedInfo .info-box .remark textarea {
    margin: 0;
    border: none;
    width: 100%;
    height: 80px;
    padding: 10px;
    box-sizing: border-box;
    resize: none;
}

/* .tableWrap.feedback input[type="text"], input[type="number"], input[type="password"], select, {
    padding:3px 10px;
} */


/* 선순위 임차보증금 내역서 */

.tableWrap.winnerRank .viewerContainer{
    padding : 0 30px 30px ;
    box-sizing: border-box;
}

.tableWrap.winnerRank .viewerContainer{
    border:none
}
.tableWrap.winnerRank th{
    background-color: #ddd;
}

.tableWrap.winnerRank .viewerContainer tbody td input[type=text],
.tableWrap.winnerRank .viewerContainer tbody td input[type=number]{
    background-color: transparent;
    width:50%;
    min-width:80px;
    border:none;
    text-align: center;  
}

.tableWrap.winnerRank table:last-child th,
.tableWrap.winnerRank table:last-child td{
    border:none;
    padding:3px 0
}
.tableWrap.winnerRank table:last-child th{
    background-color: transparent;
    text-align: left;
    padding-left : 5px;
}