body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-color: #F6F6F6;
  font-family: 'Noto Sans KR', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
   
}

*{
  margin: 0;
  padding: 0;
}

  /*========================================================
    정렬 공통 적용
  ========================================================*/
  /**** 수직정렬 ****/
  .vertical-t{vertical-align: top !important;}
  .vertical-m{vertical-align: middle !important;}

  /**** 수평정렬 ****/
  .text-r{text-align: right !important}
  .text-l{text-align: left !important}
  .text-c{text-align: center !important}
  
  /**** 간격 ****/
  .mt0{margin-top:0px !important}
  .mt2{margin-top:2px !important;}
  .mt5{margin-top:5px !important;}
  .mt10{margin-top:10px !important;}
  .mt15{margin-top:15px !important;}
  .mt20{margin-top:20px !important;}
  .mt25{margin-top:25px !important;}
  .mt30{margin-top:30px !important;}
  .mt35{margin-top:35px !important;}
  .mt40{margin-top:40px !important;}
  .mt45{margin-top:45px !important;}
  .mt50{margin-top:50px !important;}
  .mt55{margin-top:55px !important;}
  .mt60{margin-top:60px !important;}
  .mt65{margin-top:65px !important;}
  .mt70{margin-top:70px !important;}
  .mt75{margin-top:75px !important;}
  .mt80{margin-top:80px !important;}
  .mt95{margin-top:95px !important;}
  .mt420{margin-top:420px !important;}
  .mb0{margin-bottom:0px !important;}
  .mb5{margin-bottom:5px !important;}
  .mb10{margin-bottom:10px !important;}
  .mb15{margin-bottom:15px !important;}
  .mb20{margin-bottom:20px !important;}
  .mb25{margin-bottom:25px !important;}
  .mb30{margin-bottom:30px !important;}
  .mb35{margin-bottom:35px !important;}
  .mb40{margin-bottom:40px !important;}
  .mb45{margin-bottom:45px !important;}
  .mb50{margin-bottom:50px !important;}
  .mb55{margin-bottom:55px !important;}
  .mb60{margin-bottom:60px !important;}
  .mb65{margin-bottom:65px !important;}
  .mb70{margin-bottom:70px !important;}
  .mb75{margin-bottom:75px !important;}
  .mb80{margin-bottom:80px !important;}
  .mg-auto{margin: 0 auto !important;}
  .mr5{margin-right:5px !important;}
  .mr10{margin-right:10px !important;}
  .mr20{margin-right:20px !important;}
  .mr80{margin-right:80px !important;}
  .ml5{margin-left:5px !important;}
  .ml10{margin-left:10px !important;}
  .p0{padding: 0 !important;}
  .pl5{padding-left: 5px !important}
    .pl7{padding-left: 7px !important}
  .pl10{padding-left: 10px !important}
  .pl10{padding-left:10px !important}
  .pl26 {padding-left:26px !important}
    .pr7 {padding-right:7px !important}
  .bd0{border:0 !important}
  .bdt0{border-top:0 !important}
  .bdb0{border-bottom:0 !important}

  

  
/*========================================================
    input[text, date, number, password], select 공통 적용 
========================================================*/
  select,input[type="date"]{padding: 5px 10px;border-radius: 3px; color: #545454;box-sizing: border-box;border: 1px solid #BDBDBD; cursor: pointer;}
  input[type="text"],input[type="number"],input[type="password"]{padding: 5px 10px;border-radius: 3px; color: #545454;box-sizing: border-box;border: 1px solid #BDBDBD;} 
  input::placeholder, textarea::placeholder { color: #bdbdbd;}
  /*========================================================
   테이블
  ========================================================*/

/**** board-form ****/
.board-form{width:100%;}
.board-form table{border-collapse:separate; border-spacing: 5px 5px}
.board-form table{width:100%;position:relative;table-layout: fixed;}
.board-form table th{font-size:13px; color:#545454; vertical-align: middle; word-break: break-all;padding:5px 0px; text-align: center; font-weight: normal;}
.board-form table td{font-size:13px; color:#05141f; vertical-align: middle; word-break: break-all;padding:5px 0px; border:1px solid #BDBDBD;border-radius: 3px; background-color: #fff;}
.board-form table tbody th{ background:#DDDDDD; border-radius: 3px;}

/* .board-form .w-col{width:200px;}
.board-form table tbody th > .in-tb [class^="btn-"]{position:absolute; top:50%; right:0; -webkit-transform:translateY(-50%); transform:translateY(-50%);}
.board-form table tbody th em{font-weight: normal;}
.board-form .in-tb{padding:5px 0 6px}
.board-form td button + button {margin-left: 2px;}
.board-form td a.in-tb{text-decoration:underline} */


/**** list-form ****/
.tt{width: 100%; border-collapse: collapse;  text-align: center;border-radius: 0.31rem; background-color: #ffffff;box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16);table-layout: auto; white-space: nowrap; }
/* .tt thead tr th{text-overflow: ellipsis;white-space: nowrap;overflow: hidden;word-break: break-all;} */
.tt thead tr th:first-child{width: 40px}
  .tt th, .tt td{padding:0 5px;}
  .tt th:first-child,tt td:first-child{padding-left: 10px;}
  .tt th:last-child,tt td:last-child{padding-right: 10px;width:40px}
  /* max-width 150px -> none */
  .tt td{ color: #545454; }
.tt thead{ border-bottom: 1px solid #0B999C;}
.tt thead tr{color:#0B999C; font-size: 0.93rem;font-weight: 600;}
.tt tr{height: 2.75rem;font-size: 14px;}
.tt tbody tr:nth-child(2n-2){background-color: #F6F6F6;}
  .tt tbody tr:hover{cursor: pointer;background-color: #ccc;}
.tt th span, .tt td span{padding: 5px 0}
.table th span, .table td span{padding: 5px 0}

  .tt{width: 100%; text-align: center;border-radius: 0.31rem; background-color: #ffffff;box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); table-layout: auto;}
  .tt .wrap> div{padding:0 10px}
  .tt .thead{ border-bottom: 1px solid #0B999C;}
  .tt .thead .wrap{color:#0B999C; font-size: 0.93rem;font-weight: 600;}
  .tt .wrap{height: 2.75rem;font-size: 14px;}
  .tt .tbody .wrap:nth-child(2n-2){background-color: #F6F6F6;}
  .tt .tbody .wrap:hover{cursor: pointer;background-color: #ccc;}
  .tt th span, .tt td span{padding: 5px 0}
  .table th span, .table td span{padding: 5px 0}
  
    /* .tt thead {position: relative; position:fixed;background-color: #fff;width:94%;z-index: 9999;} */
    /* .tt thead::after{content: '';clear: both;display: block;} */
    .tt thead {overflow: hidden;}
    .tt thead th{position: sticky;top:0;background-color: #fff;z-index: 100;}
    /* .tt thead th::before, */
    .tt thead th::after{content:'';position: absolute;left:0;width:100%;}
    /* .tt thead th::before{top:-1px;border-top:1px solid #08999c} */
    .tt thead th::after{bottom:-1px; border-bottom:1px solid #08999c}
  

/**** div안에 테이블태그있는거 공통적용(ex 당첨자 추가 리스트 등) ****/
.tableCover {  height: 55vh; overflow: auto; box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16);}
/**** box-shadow없는 list-form ****/
.shadow-none-tt{width: 100%; border-collapse: collapse; text-align: center;border-radius: 0.31rem; background-color: #ffffff;margin-bottom: 3.75rem;}
.shadow-none-tt th, .shadow-none .tt td{padding:0 10px}
.shadow-none-tt thead{ border-bottom: 1px solid #0B999C;}
.shadow-none-tt thead tr{color:#0B999C; font-size: 0.93rem;font-weight: 600;}
.shadow-none-tt tr{height: 2.75rem;font-size: 14px;}
.shadow-none-tt tbody tr:nth-child(2n-2){background-color: #F6F6F6;}
.shadow-none-tt tbody tr:hover{cursor: pointer;background-color: #ccc;}

/**** 테두리가있는 table ****/
  .border-tt{width: 100%; border-collapse: separate; border-spacing: 0; -moz-border-radius:5px; text-align: center; /* border: 1px solid #C5C5C5; */ border-radius: 5px; background-color: #ffffff;margin-bottom: 3.75rem;}
.border-tt th, .border-tt td{padding:0 10px}
.border-tt thead tr{color:#0B999C; font-size: 0.93rem;font-weight: 600;}
  /* .border-tt thead tr th{ border-bottom: 1px solid #0B999C;} */
.border-tt tr{height: 2.75rem;font-size: 14px;}
.border-tt tbody{height: 2.75rem;font-size: 14px; color: #545454;}
.border-tt tbody tr:hover{cursor: pointer;background-color: #ccc;}
.border-tt tbody td{border-left: 0.5px solid #BDBDBD; border-top: 0.5px solid #BDBDBD;}
.border-tt td:first-child{ border-left: none !important;}
.border-tt td:last-child{ border-right: none !important;}
  .border-tt thead{overflow: hidden;}
  .border-tt thead th{position: sticky;top:0;background-color: #fff;z-index: 100;}
  .border-tt thead th::after{content:'';position: absolute;left:0;width:100%;}
  .border-tt thead th::after{bottom:-1px; border-bottom:1px solid #08999c}
  


/*========================================================
버튼
========================================================*/
/**** btn ****/
.prev-btn{ width: 88px; height: 34px; color: #808080; font-size: 14px; font-weight: 600; display: flex; align-items: center; justify-content: center; border-radius: 3px; border: 1px solid #808080; background-color: #ffffff; cursor: pointer;}
.next-btn{ width: 88px; height: 34px; color: #1EBF86; font-size: 14px; font-weight: 600; display: flex; align-items: center; justify-content: center; border-radius: 3px; border: 1px solid #1EBF86; background-color: #ffffff; cursor: pointer;}
.cancel-btn{ width: 88px;height: 34px; color: #808080; font-size: 14px; font-weight: 600; display: flex; align-items: center; justify-content: center; border-radius: 3px; border: 1px solid #808080; background-color: #ffffff; cursor: pointer;}
.pop-cancel-btn{ width: 30%;height: 48px; color: #ffffff; font-size: 19px; font-weight: 600; display: flex; align-items: center; justify-content: center; border-radius: 3px; border: 1px solid #808080; background-color: #808080; cursor: pointer;}
.bdnone-cancel-btn{ border: none; margin: 10px 20px 0 0; background-color: transparent; cursor: pointer;}
.save-btn{ width: 88px; height: 34px; color: #0B9A9C; font-size: 14px; font-weight: 600; display: flex; align-items: center; justify-content: center; border-radius: 3px; border: 1px solid #0B9A9C;; background-color: #ffffff; cursor: pointer;}
.pop-save-btn{ width: 30%; height: 48px; color: #ffffff; font-size: 19px; font-weight: 600; display: flex; align-items: center; justify-content: center; border-radius: 3px; border: 1px solid #1EBF86; background: linear-gradient(to bottom, #1EBF86 , #0B999C); cursor: pointer;}
.add-btn{ width: 60px; height: 26px; color: #0B999C;font-weight: 600; font-size: 12px; border-radius: 1.87rem; border: 1px solid#0B999C;background-color: #ffffff; cursor: pointer;}
.sch-btn{ width: 60px; height: 26px; color: #0B999C; font-size: 12px; font-weight: 600; border-radius: 1.87rem; border: 1px solid #0B999C; background-color: #ffffff; cursor: pointer;}
.pop-sch-btn{ width: 60px; height: 26px; color: #0B999C; font-size: 14px; font-weight: 600; border-radius: 3px; border: 1px solid #0B999C; background-color: #ffffff; cursor: pointer;}
.del-btn{ width: 60px; height: 26px; color: #808080; font-size: 12px; font-weight: 600; border-radius: 1.87rem; border: 1px solid #808080; background-color: #ffffff; cursor: pointer;}
.x-del-btn{ width: 19px; height: 18px; color: #BDBDBD; font-size: 8px; border-radius: 50%; border: 1px solid #BDBDBD; background-color: #ffffff; cursor: pointer;}
.minus-del-btn{ width: 25px; height: 25px; color: #BDBDBD; font-size: 14px; border-radius: 50%; border: 1px solid #BDBDBD; background-color: #ffffff; cursor: pointer;}
.calculation-btn{ padding: 4px 17px; box-sizing: border-box; color: #0B999C;font-weight: 600; font-size: 12px; border-radius: 1.87rem; border: 1px solid#0B999C;background-color: #ffffff; cursor: pointer;}
  .green-sch-btn{ width: 90px; height: 36px; color: #ffffff; display: flex; align-items: center; justify-content: center; padding: 4px 10px; border: none; border-radius: 3px; background: linear-gradient(to bottom, #1EBF86 , #0B999C); box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); cursor: pointer;}
  .reset-btn{ color: #545454; border: none; padding: 4px 10px; border-radius: 3px; box-sizing: border-box; background-color: rgb(221, 221, 221);  box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16);}
.address-btn{ width: 90px; height: 28px; color: #0B999C; padding: 4px 10px; border: 1px solid #0B999C; border-radius: 2px; background-color: #ffffff; cursor: pointer;}
.print-btn{ width: 88px; height: 34px; color: #0B999C; padding: 4px 10px; border: 1px solid #0B999C; border-radius: 3px; background-color: #ffffff; cursor: pointer;font-weight: 600;}
.request-btn{ width: 80px; color: #0B999C; display: flex; align-items: center; justify-content: center; padding: 2px 4px; border-radius: 3px; border: 1px solid #0B999C; background-color: #ffffff; cursor: pointer;}
.final-btn{ width: 80px; color: #0B999C; display: flex; align-items: center; justify-content: center; padding: 2px 4px; border-radius: 3px; border: 1px solid #0B999C; background-color: #ffffff; cursor: pointer;}
.bdradius-btn{ color: #0B999C; font-size: 12px; font-weight: 600; padding: 10px 12px;  border-radius: 1.87rem; border: 1px solid #0B999C; background-color: #ffffff; cursor: pointer;}
.squre-bdradius-btn{ height: 38px; color: #0B999C; font-weight: 600; display: flex; align-items: center; justify-content: center; padding: 12px 15px; border-radius: 5px; border: 1px solid #0B999C; background-color: #ffffff; cursor: pointer;}
.support-cc-btn{ width: 100px; height: 32px; color: #ffffff; font-size: 15px; border: none; border-radius: 16px; background-color: #1BB88B; cursor: pointer;}
.up-btn, .down-btn{ margin: 0 2px; background-color: #ffffff; border: 1px solid #C5C5C5; border-radius: 3px; cursor: pointer;}

.fixed-bdradius-btn{ color: #0B999C; font-size: 12px; font-weight: 600; padding: 4px 6px;  border-radius: 1.87rem; border: 1px solid #0B999C; background-color: #ffffff; cursor: pointer;}

.tab-btn{ color: #0B999C; font-weight: 600; display: inline-block; align-items: center; justify-content: center; padding: 6px 10px;  box-sizing: border-box; border-radius: 3px; border: 1px solid #0B999C; background-color: #ffffff; cursor: pointer;}

.dkgreen-btn{ color: #0B999C; font-size: 15px; font-weight: 600; padding: 5px 10px 4px 10px; box-sizing: border-box; border-radius: 3px; border: 1px solid #0B999C; background-color: #ffffff; cursor: pointer;}

/* color-btn */
.divFlex-btn button{padding: 2px 4px; margin-right:8px;cursor:pointer;border-radius:3px; font-size:16px}
  .divFlex-btn label{padding: 0px 4px; margin-right:8px;cursor:pointer;border-radius:3px; font-size:16px}
.btn1{background-color:#fff;color:#217346;border: 1px solid #217346;}   
.btn2{background-color:#fff;color:#0B999C;border: 1px solid #0B999C;}    
.btn3{background-color:#fff;color:#808080;border: 1px solid #808080;}    
.btn4{background-color:#fff;color:#1EBF86;border: 1px solid #1EBF86;}
.btn5{background: linear-gradient(to bottom,#1EBF86 ,#0B999C);}


/* divButtons */
.divButtons{  width: 100%; display: flex; justify-content: center; }
.divButtons button{  width: 50%; margin: 0 10px; cursor: pointer; height: 48px; color: #ffffff; font-size: 19px; font-weight: 500; display: flex; align-items: center; justify-content: center; border-radius: 3px; border: none;}
.divButtons .divB-Cancel{ background-color: #808080; }
.divButtons .divB-Save{ background: linear-gradient(to bottom, #1EBF86 , #0B999C);}

/* 팝업창 세개버튼 */
.threeButtons{text-align: center;}
  .threeButtons button{ margin: 0 10px; cursor: pointer;}
  .threeButtons button:nth-child(1){min-width: 100px;padding:8px 0; border:1px solid #0B999C;  background-color: #fff; border-radius: 3px; color: #0B999C;}
  .threeButtons button:nth-child(2){min-width: 100px;padding:8px 0; border:1px solid #1EBF86; background-color: #fff; border-radius: 3px; color:#1EBF86;}
  .threeButtons button:nth-child(3){min-width: 100px;padding:8px 0; border:1px solid #808080; background-color: #fff; border-radius: 3px; color:#808080;}
  
/* .header .cancel-btn-ico{width:16px;height: 16px;position: relative;border:0;background-color: #fff;cursor: pointer;}
.header .cancel-btn-ico::before{transform: rotate(45deg);position:absolute;top:50%;left:-15px;display: block;content:"";width:130%;height: 1px;box-shadow: inset 0 0 0 32px; border:1px solid #bdbdbd}
  .header .cancel-btn-ico::after{bottom:0;transform: rotate(-45deg);position:absolute;top:50%;left:-15px;display: block;content:"";width:130%;height: 1px;box-shadow: inset 0 0 0 32px; border:1px solid #bdbdbd} */

/**** btn:hover ****/
.prev-btn:hover,.del-btn:hover,.cancel-btn:hover,.doc-btn-group button:nth-child(1):hover, .btn-group button:nth-child(3):hover, .pop-group-btn button:nth-child(1):hover{ color: #ffffff; background-color: #808080;}
.next-btn:hover,.doc-btn-group button:nth-child(3):hover, .btn-group button:nth-child(4):hover, .pop-group-btn button:nth-child(3):hover{ color: #ffffff; background-color: #1EBF86;}
.add-btn:hover,.save-btn:hover, .squre-bdradius-btn:hover, .bdradius-btn:hover,.address-btn:hover,.doc-btn-group button:nth-child(2):hover, .request-btn:hover, .btn-group button:nth-child(2):hover, .pop-group-btn button:nth-child(2):hover, .calculation-btn:hover, .tab-btn:hover,.dkgreen-btn:hover, .print-btn:hover{ color: #ffffff; background-color:#0B999C;}
.btn-group button:nth-child(1):hover{ color:#ffffff;  border: 1px solid #217346; background-color:#217346; }
.tableCover table tbody tr:hover{ background-color: #ccc; cursor: pointer; }

/*========================================================
버튼 아이콘
========================================================*/
.prev-btn::before{ content:"<"; width: 4px; height: 8px; display: inline-block; align-items: center; margin: -4px 8px 0 0;}
.next-btn::after{ content:">"; width: 4px; height: 8px; display: inline-block; align-items: center; margin: -4px 0 0 8px;}
.sch-btn::before{ content:""; background-image: url(./svgfolders/searchicon_color.svg); background-repeat: no-repeat;  width: 0.81rem; height: 0.81rem; display: inline-block; align-items: center; margin-right: 0.25rem; vertical-align: bottom; box-sizing: border-box; }
.pop-sch-btn::before{ content:""; background-image: url(./svgfolders/searchicon_color.svg); background-repeat: no-repeat; width: 0.81rem; height: 0.81rem; display: inline-block; align-items: center; margin-right: 0.25rem;}
  .green-sch-btn::before{ content:""; background-image: url(./svgfolders/searchicon.svg);  background-repeat: no-repeat; width: 0.81rem; height: 0.81rem; display: inline-block; align-items: center; margin-right: 0.25rem; }
  
 
/*========================================================
folderSlider 버튼 공통 적용
========================================================*/
.folderBox { width: 100%; display: flex; align-items: center; border-top: 1px solid #C5C5C5;}
.folderBox > div { width: 100%;height: 40px;overflow: hidden;position: relative;}
.folderBox button { border: none; background-color: transparent; cursor: pointer; }
.folderSlider {display: flex;position: absolute;}
.folderSlider > div{ min-width: 166px; height: 40px; margin-right: 1px; text-align:center; color:#ffffff; font-size: 15px; display: flex; align-items: center; justify-content: center; box-sizing: border-box;background-color: #BDBDBD;cursor: pointer;}

/*========================================================
divImages 공통 적용
========================================================*/
.divImages { position: relative; display: flex; flex-direction: column; flex-wrap: nowrap; justify-content: space-between; border-radius: 5px; border: 1px solid #C5C5C5; background-color: #ffffff;}
.divImages .imgView { position: relative; width: 100%; height: 100%; overflow: hidden; padding: 10px; box-sizing: border-box;}
.selectImg {width: 100%;height: 100%;object-fit: contain;position: absolute;border: 1px solid #ccc;cursor: all-scroll; box-sizing: border-box;}
.navImgBox { position: absolute; top: 0; right: 0; height: -webkit-calc(100% - 40px); overflow-y: scroll; opacity: 0.5;}
.navImgBox:hover { opacity: 1;}
.navImg { width: 120px; height: 120px; border-radius: 5px; border: 1px solid #C5C5C5; background-color: #ffffff; box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16);}
.navImg:hover { border: 1px solid red;}
.navImg img { width: 100%; height: 100%;  border-radius: 5px; object-fit: cover; cursor: pointer; box-sizing: border-box;}
  /*========================================================
    checkBox, Radio, file 버튼 공통 적용
  ========================================================*/
  input[type="checkbox"]{position:absolute; width:1px; height:1px; padding:0; margin:-1px; overflow:hidden; clip:rect(0,0,0,0); border:0; vertical-align:top;}
  input[type="checkbox"] + label{display:inline-block; position:relative; cursor:pointer; vertical-align: top; line-height:20px;padding-left:20px; min-height:20px;}
  input[type="checkbox"] + label:before{display:block; content:''; position:absolute; left:0; top:3px; width:14px; height:14px; text-align:center; background:#fff; border:1px solid #1EBF86;box-sizing:border-box; border-radius:2px;} 
  input[type="checkbox"]:checked + label:before{content : '✔'; width:14px; height:14px; border:1px solid #1EBF86;border-radius:2px; color: #1EBF86;line-height: 0.9; font-size:10px;}
  
  input[type="radio"]{position:absolute; width:1px; height:1px; padding:0; margin:-1px; overflow:hidden; clip:rect(0,0,0,0); border:0; vertical-align:top;}
  input[type="radio"] + label{display:inline-block; position:relative; cursor:pointer; vertical-align: top; line-height:20px;padding-left:20px; min-height:20px;}
  input[type="radio"] + label:before{display:block; content:''; position:absolute; left:0; top:3px; width:14px; height:14px; text-align:center; background:#fff; border:1px solid #1EBF86;box-sizing:border-box; border-radius:100%;} 
  input[type="radio"]:checked + label:before{background:#fff;border:1px solid #1EBF86;}
  input[type="radio"]:checked + label:after{content : ''; position:absolute;top:7px; left:4px;width:6px;height: 6px;background:#1EBF86;border-radius: 100%;}

    input[type="file"]{position: absolute;width:1px;height: 1px;padding:0;margin:-1px;overflow: hidden;clip: rect(0,0,0,0);border:0}
    /* .btn-form{display:inline-block;padding:2px 4px;border-radius:3px;text-align: center;background-color:#fff;color:#217346;border:1px solid #217346;white-space: nowrap;}
    .btn-form > span{display:inline-block;height:100%;}
    .upload-name{display:inline-block;vertical-align: middle;appearance:none}
    .upload-name .txt{display:inline-block;max-width: 300px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;word-break: break-all;padding:0 5px;vertical-align: middle;}
    .btn-del-xs{width:18px;height: 18px;border-radius: 50%;background: #bdbdbd;padding:0 7px}
    .btn-del-xs{width:8px;height: 8px;box-shadow: none;position:relative;display:block;}  
    [class^="ico-x-"]:before,[class^="ico-x-"]:after{position:absolute;top:50%;left:-3px;display:block;content:'';width:130%;height:1px;box-shadow: inset 0 0 0 32px;;}
    [class^="ico-x-"]:before{transform:rotate(45deg)}
    [class^="ico-x-"]:after{transform:bottom;transform: rotate(-45deg);} */
  
  
  
  
  
    
  

  
  
  
  
    
  /*========================================================
   검색조건
  ========================================================*/
  /* .search{margin: 10px 0 50px 0;}
  .search form{width: 100%; display: flex;align-items: center;padding: 30px; box-sizing: border-box;border-radius: 0.31rem;border: 1px solid #BDBDBD;background-color: #ffffff;white-space: nowrap;overflow: hidden;}
  .searchBody{width: 100%;display: flex; justify-content: space-between; white-space: nowrap;}
  .search .ipt{width: 100%; display: flex;align-items: center;justify-content: space-between;white-space: nowrap;}
  .search .searchipt{width: 100%;display: flex; flex-wrap: wrap; gap: 10px; column-gap: 20px;align-items: center;justify-content: flex-start;white-space: nowrap;}
  .search .st span{ margin: 0 4px;}
   */
  /* .search form{width: 100%; background-color: #fff; text-align: center;box-sizing: border-box;padding:25px 0px; border-radius: 5px; border:1px solid #C5C5C5}
  .searchBody{position:relative;}
  .searchBody .ipt, .btn{display: inline-block;position: relative;white-space: nowrap;vertical-align: middle;}
  .searchBody .ipt .bg, .st{display: inline-block;position: relative;white-space: nowrap;vertical-align: middle;} */
  
  /**** 검색조건 type1(.searchBody .ipt) ****/
  /* .searchBody .ipt .bg{ height: 36px; padding-top: 5px; box-sizing: border-box; color: #545454; font-weight: 500; border-radius: 3px;background-color: #DDD;min-width: 80px;text-align: center; margin-right: 3px;}
  .searchBody .ipt label.bg{ display: inline-block;}
  .searchBody .ipt .st{ display: flex; align-items: center;}
  .searchBody .ipt .st>div{ margin: 0 5px; display: flex; align-items: center;}
  .ipt .st{margin-right: 10px;}
  .ipt .search .checkbox {align-items: center} */
    /* .ipt select,input[type="text"],input[type="date"]{height: 30px;padding: 0 10px;border-radius: 3px;box-sizing: border-box;border: 1px solid #BDBDBD;} */
  /* .ipt .st label{margin-right: 5px;}
  .ipt select{width:120px; height: 36px;}
  .ipt input[type="date"]{width:150px; min-width:120px; height: 36px; text-align: center;}
  .ipt input[type="text"]{width:80px; min-width:60px; height: 36px;} */

  
  /**** 검색조건 type2(.searchBody .searchipt) ****/
  /* .searchipt .search .checkbox {align-items: center} */
    /* .searchipt select,input[type="text"],input[type="date"]{height: 30px;padding: 0 10px;border-radius: 3px;box-sizing: border-box;border: 1px solid #BDBDBD;} */
  /* .searchipt select{ width:120px; height: 36px;}
  .searchipt .st:last-child select{width:140px; margin-right: 5px;}
  .searchipt .st { display: flex; align-items: center;}
  .searchipt .st .input-user{width:100px; height: 36px;padding: 0 10px;border-radius: 3px;  margin: 0 10px; box-sizing: border-box;border: 1px solid #BDBDBD;}
  .searchipt input[type="date"]{width:100%; min-width:120px; height: 36px; text-align: center;}
  .searchipt input[type="number"]{width:150px; min-width:120px; height: 36px;}
  .searchipt input[type="text"]{width:80px; min-width:60px; height: 36px;}
  .searchipt .st label{color: #545454;  height: 36px;  padding-top: 5px; box-sizing: border-box; border-radius: 3px; background-color: #DDD;min-width: 100px;text-align: center; display: inline-block; align-items: center; font-weight: 500; margin-right: 4px;}
   */

   /* 검색조건 공통 */
  .search{width: 100%; border-radius: 0.31rem; border: 1px solid #BDBDBD; background-color: #ffffff; margin-bottom: 30px;}
  .search form{padding: 20px;box-sizing: border-box;}
  .search select, .search input[type="text"]{ font-size: 14px;}
  .search .searchBody{width: 100%; display: flex; }
  .search .searchBody .btn{width: 15%;display: flex;justify-content: flex-end;}
  .green-sch-btn,.reset-btn{width: 90px; height: 100%; font-size: 15px; margin-left: 10px; cursor: pointer;}


 
   /* 검색조건 1 list.js*/
  .search .searchipt select,.search .searchipt input[type="date"],.search .name-user input,.search .searchipt input[type="text"]{height: 36px;box-sizing: border-box;}
  .search .searchipt input[type="date"],.name-user select,.name-user input{width: 142px !important;box-sizing: border-box;}
  .search .searchipt select,.search .searchipt input[type="text"]{width: 192px;}
  .search .searchipt input[type="date"]{ min-width:142px; height: 36px;}
  .search .searchipt .name-user select{margin-right: 8px;}
  .search .name-user input{border-radius: 3px; border: 1px solid #BDBDBD; padding: 0 10px; box-sizing: border-box;}
  .search .searchipt{ width: 85%;display: flex;flex-wrap: wrap;justify-content: flex-start;}
  .searchipt .st{width: 30%;display: flex;align-items: center; margin-bottom: 5px; }
  .searchipt .st:nth-child(1),.searchipt .st:nth-child(4){margin-right: 10%;}
  .searchipt .st label:not(.notlabel){ min-width: 100px; width: 100px;height: 36px; color: #545454; font-weight: 500; display: flex; white-space: nowrap; align-items: center;justify-content: center;margin-right: 5px;border-radius: 3px;background-color: #DDD;}
  

   /* 검색조건 2 당첨자추가,묵시연장추가리스트 작업중*/
   
  .search .ipt{width: 85%;display: flex;flex-wrap: wrap; white-space: nowrap; align-items: center; justify-content: flex-start;}
  .search .ipt select,.search .ipt input[type="text"]{width: 162px; height: 36px;}
  .search .ipt input[type="date"]{ min-width: 162px; height: 36px;}
  .ipt .st label:not(.checkbox label){ min-width: 100px; width: 100px !important;height: 36px; color: #545454; font-weight: 500; display: flex; white-space: nowrap; align-items: center;justify-content: center;margin-right: 5px;border-radius: 3px;background-color: #DDD;}
  .ipt .st{width: 28%;display: flex;align-items: center; margin-bottom: 5px;}
  .ipt .st:nth-child(2),.ipt .st:nth-child(5){width: 40%;}
  .ipt .st>div{display: flex; align-items: center;}
  .search .ipt .checkbox { justify-content: flex-start; margin-bottom: 0;}
  .ipt .checkbox > div{ color:#545454; margin: 0 20px;}

/* 검색조건 3 사전/사후관리*/
  .search .ipt2{width: 85%;display: flex; align-items: center; justify-content: space-between;}
  .search .ipt2 select,.search .ipt2 input[type="text"]{width: 162px; height: 36px;}
  .search .ipt2 input[type="date"]{ min-width:150px; height: 36px;}
  .ipt2 .st2{width: 25%;display: flex;align-items: center; margin-bottom: 5px;}
  .ipt2 .st2:nth-child(1){ width: 40%; margin-right: 40px;}
  .ipt2 .st2 label{ min-width: 100px; width: 100px !important;height: 36px; color: #545454; font-weight: 500; display: flex;align-items: center;justify-content: center;margin-right: 5px;border-radius: 3px;background-color: #DDD;}
  .ipt2 .st2:nth-child(2) select,.ipt2 .st2:nth-child(3) select{ width: 100px;}
 

/* 검색조건 4 묵시작업결재페이지*/
  .search .ipt3{width: 85%;display: flex; align-items: center; justify-content: space-between;}
  .search .ipt3 select,.search .ipt3 input[type="text"]{width: 162px; height: 36px;}
  .search .ipt3 input[type="date"]{ min-width:150px; height: 36px;}
  .ipt3 .st3{width: 25%;display: flex;align-items: center; margin-bottom: 5px;}
  .ipt3 .st3 label{ min-width: 120px; width: 120px !important;height: 36px; color: #545454; font-weight: 500; display: flex;align-items: center;justify-content: center;margin-right: 5px;border-radius: 3px;background-color: #DDD;}
  .ipt3 .st3:nth-child(1){ width: 40%; margin-right: 40px;}
  .ipt3 .st3:nth-child(1)>select{ margin-right: 5px;}


/* 검색조건 5 고객리스트*/
.search .ipt4{width: 85%;display: flex; flex-wrap: wrap; align-items: center; justify-content: flex-start;}
.search .ipt4 select,.search .ipt4 .st4 .input-size{ min-width: 162px; width: 162px; height: 36px; box-sizing: border-box;}
.search .ipt4 input[type="date"]{ min-width:150px; height: 36px;}
.ipt4 .st4{width: 25%;display: flex;align-items: center; margin-bottom: 5px;}
.ipt4 .st4 label{ min-width: 120px; width: 120px !important;height: 36px; color: #545454; font-weight: 500; display: flex;align-items: center;justify-content: center;margin-right: 5px;border-radius: 3px;background-color: #DDD;}
.ipt4 .st4:nth-child(1),.ipt4 .st4:nth-child(4){ width: 45%; margin-right: 40px;}
.ipt4 .st4:nth-child(4) .input-size{width: 150px;}
.ipt4 .st4:nth-child(1)>select{ margin-right: 5px;}




/*========================================================
  header 버튼 아이콘
========================================================*/
/* .divHeader-button{position:relative;top:-67px;margin-left:10%; z-index: 110; display: flex; flex-direction: row; gap: 10px;margin-right: 20%;} */
  /* .divHeader-button{position: fixed; top: 13px; left: 230px; z-index: 110; display: flex; flex-direction: row; gap: 10px;} */
  .divHeader-button{position: absolute; top: -46px; left: 230px; z-index: 110; display: flex; flex-direction: row; gap: 10px;}
.divHeader-button button{background-color: #fff; color:#0B999C; font-weight: 600; border:0; min-width: 60px; padding : 8px 8px; text-align: center; border-radius: 3px; box-shadow: 0 3px 6px inset rgba(0,0,0,0.16); cursor: pointer;}
  
  /*========================================================
    관리자페이지 버튼 레이아웃
  ========================================================*/
  .buttonWrap{max-width:1920px; min-width: 320px; margin:0 auto; padding: 10px 50px 0; box-sizing: border-box;}
    
  
  /*========================================================
  modal
  ========================================================*/
  .winnerModal {width: 100%;height: 100vh;position: fixed;top: 0;left: 0;display: flex;align-items: center;justify-content: center;z-index: 200;}
  .winnerModal > div:nth-child(1) {position: absolute; left: 0;top: 0;width: 100%;height: 100%;background-color: #a1a1a199;}
  .winnerModal > div:nth-child(2) {position: absolute; max-width:1288px; width: 65%;padding: 30px 20px;background-color: white; border-radius: 10px; box-shadow: 0 8px 20px rgba(0,0,0,0.16);}
  .winnerModal .header{display: flex;align-items: center;justify-content: center;}
  .winnerModal .body { margin-bottom: 10px;}
  .winnerModal .body > div{display: flex;justify-content: flex-start;flex-wrap: wrap; align-items:center; column-gap: 20px;}
  .winnerModal .body > h4 {margin:10px 0 2px 0;}
  .winnerModal .body > div > div{display: flex;flex-direction: column;}
  .winnerModal .header h2{color:#545454; font-size: 26px; margin-bottom: 50px;}
  .winnerModal .body > div > div label, .label{ color: #545454;}
  .winnerModal .body > div > div select, .winnerModal .body > div > div input{ width: 176px; height: 26px;}

  .winnerModal .body > div > div textarea{resize: none; padding: 5px 10px;border-radius: 3px; color: #545454;box-sizing: border-box;border: 1px solid #BDBDBD;}
  .winnerModal .body > div > div input{ width: 176px; height: 26px;}
  .winnerModal .body > div > div > .width2{ width: 372px !important; height: 26px;}
  .winnerModal .body > div > div > .height2{height: 52px;}
  .winnerModal .body > div > div > .iptErr{ border: 1px solid red;}
  .winnerModal .body > div > div .checkBox { position: relative; width: 26px !important;}
  .winnerModal .body > div > .center{align-items: center;}

  .winnerModal .body > div .bottom {justify-content: flex-end;}
  .winnerModal .body > div .bottom button {height: 100%;}

  .winnerModal .greenDot { position: absolute; margin: 6px 3px 0 3px; width: 5px; height: 5px; background-color: #0fa396; border-radius: 100%; }
  .winnerModal .body .right { text-align: right; padding-right: 35px;}
  .body{padding:0 30px}
  .body h4{font-size: 14px; color:#0B999B; }
  .body h4 ~ div > div{margin-bottom: 5px}
  .body > div:nth-child(odd){margin-bottom:5px}
  .body label, .label{font-size: 12px;margin-bottom: 3px;}
  .body .fmly_tbl{margin-top: 5px}
  .body .fmly_tbl > div{width: 100%; max-height: 200px; overflow-y: scroll; position:relative;border:1px solid #bdbdbd; border-radius: 3px;}
  .pop-group-btn{text-align: center;}
  .pop-group-btn button:not(:last-child){margin-right: 15px;}
  .pop-group-btn button:nth-child(1){min-width: 80px;padding:8px 0; border:1px solid#808080; background-color: #fff; border-radius: 3px; color:#808080;cursor: pointer;}
  .pop-group-btn button:nth-child(2){min-width: 100px;padding:8px 0; border:1px solid#0B999C; background-color: #fff; border-radius: 3px; color:#0B999C;cursor: pointer;}
  .pop-group-btn button:nth-child(3){min-width: 160px;padding:8px 0; border:1px solid#1EBF86; background-color: #fff; border-radius: 3px; color:#1EBF86;cursor: pointer;}
  
  /* 셀안에 배경화면 공통 */
  .sum{color: #0B9A9C !important; font-size: 14px; font-weight: 600; background-color: rgba(11, 154, 156, 0.1)}
  .note{background-color: rgba(128, 128, 128, 0.1);}

/*========================================================
    추가하기, 삭제하기, 등록요청하기 버튼 group
  ========================================================*/
  .btn-group{ position: absolute; top:50px; right: 50px;text-align:right;}
  .btn-group button{ padding: 2px 4px; margin-right:8px;cursor:pointer;border-radius:3px; font-size:16px}
  .btn-group button:nth-child(1) {background-color:#fff;color:#217346;border: 1px solid #217346;}   
  .btn-group button:nth-child(2){background-color:#fff;color:#0B999C;border: 1px solid #0B999C;}    
  .btn-group button:nth-child(3){background-color:#fff;color:#808080;border: 1px solid #808080;}    
  .btn-group button:nth-child(4){background-color:#fff;color:#1EBF86;border: 1px solid #1EBF86;}

 
  /* 서류보완 버튼 초기화, 저장, 작업진행하기 버튼 group */
  .doc-btn-group{text-align: center;}
  .doc-btn-group button{ font-size: 14px; padding: 6px 20px; margin-right:8px; border-radius:3px; cursor:pointer;}
  .doc-btn-group button:nth-child(1){background-color:#fff;color:#808080;border: 1px solid #808080;}    
  .doc-btn-group button:nth-child(2){background-color:#fff;color:#0B999C;border: 1px solid #0B999C;}    
  .doc-btn-group button:nth-child(3){background-color:#fff;color:#1EBF86;border: 1px solid #1EBF86;}
  
/*========================================================
    파일 업로드, 팝업 공통 적용
========================================================*/  
/* 팝업 뒷 배경색 적용 */
.popupModal {width: 100%;height: 100vh;position: fixed;top: 0;left: 0;display: flex;align-items: center;justify-content: center;z-index: 200;}
.popupModal > div:nth-child(1) {position: absolute; left: 0;top: 0;width: 100%;height: 100%;background-color: #a1a1a199;}
.popupModal > div:nth-child(2) {position: absolute; width: 65%;padding: 30px 20px;background-color: white; border-radius: 10px; box-shadow: 0 8px 20px rgba(0,0,0,0.16); box-sizing: border-box;}
.popupModal > div:nth-child(2) h2{color: #545454; font-size: 26px; text-align: center; margin-bottom: 50px;}

.popupWrap{box-sizing: border-box;box-shadow: 0 8px 20px rgba(0,0,0,0.16);position:absolute;top:50%;left:50%;z-index:100; -webkit-transform: translate3d(0,0,0) translateZ(1); transform: translate3d(0,0,0) translateZ(1); transform: translateY(-50%) translateX(-50%); -webkit-transform: translateY(-50%) translateX(-50%); backface-visibility: hidden; -webkit-backface-visibility: hidden; background-color: white;position: fixed;z-index: 200;display: block !important;border-radius: 10px;}
  /* 주소 검색창 중간 배치, 공통 적용 */
  .divDaumPostcode{ position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);  z-index: 200; width: 500px; height: 450px; border: 1px solid black; background-color: #ffffff; text-align: right;}
  @media screen and (min-width: 360px) and (max-width: 1023px){ .divDaumPostcode{width: 100%; height: 100% !important; top: 0%; left: 0%; transform: translate( 0%, 0%); } .divDaumPostcode>div:nth-child(1){height: 90% !important} .divDaumPostcode > div >#__daum__layer_1{ height: 200%;} }

/*========================================================
    페이지 번호 공통 적용
========================================================*/ 
.pageNation{ color: #808080; display: flex; align-items: center; justify-content: center; margin-top: 40px;}
.page-prev-btn, .page-next-btn{ width: 1.5rem; height: 1.5rem; border-radius: 0.18rem; border: 1px solid #BDBDBD; background-color: #ffffff; cursor: pointer;}
.page-prev-btn span,.page-next-btn span{ width: 100%; display: block; text-align: center;}
.num div{ display: flex; margin: 0 10px;}
.num span{ width: 26px; height: 26px; margin: 0 3px; text-align: center; cursor: pointer;}
/* .num div span:not(.on)::after{ content:""; width: 0.06rem; height: 1rem; display: inline-block; justify-content: end; background-color:#BDBDBD;} */
.num .on{ display: block; color: #ffffff; border-radius: 0.18rem; background-color: #1EBF86;}

/* 관계자, 입주자,중개사 선택해서 로그인하는 페이지 이미지 */
.userHeader>div:first-child,.userHeader>div:last-child{display: block ;background-repeat: no-repeat; background-size: 100%;   text-indent: -99999px; }
.userHeader>div:first-child{ background-image:url(/public/lhlogo.png); width: 52px; height: 34px;}
.userHeader>div:last-child{ background-image:url(/public/sinlogo.png); width: 42px; height: 37px;}
.userTypeButton>button>div::before{content: ""; display: flex; align-items: center; justify-content: center; background-repeat: no-repeat; background-position: center; background-size: 148px; width: 93%;  height: 124px;}
.userTypeButton>button:nth-child(1)>div::before{content: "";background-image: url(./svgfolders/homeicon.svg);}
.userTypeButton>button:nth-child(2)>div::before{background-image: url(./svgfolders/brokericon.svg);}
.userTypeButton>button:nth-child(3)>div::before{background-image: url(./svgfolders/settingicon.svg);}
@media screen and (min-width: 360px) and (max-width: 1023px){
  .userHeader>div:first-child{ background-image:url(/public/lhlogo.png); width: 38px; height: 30px; vertical-align: bottom;}
  .userHeader>div:last-child{ background-image:url(/public/sinlogo.png); width: 33px; height: 30px; }
  .userTypeButton>button>div::before{ background-size: 86px; width: 96%;  height: 72px;}
}
/* 로그인 로고 */
.loginHeader div{ display: block; background-repeat: no-repeat; background-size: 100%;   text-indent: -99999px;}
.loginHeader div:first-child{ background-image:url(/public/lhlogo.png); width: 57px; height: 38px;}
.loginHeader div:last-child{ background-image:url(/public/sinlogo.png); width: 50px; height: 45px;}
@media screen and (min-width: 360px) and (max-width: 1023px){
  .loginHeader div:first-child{ width: 45px; height: 30px; }
  .loginHeader div:last-child{ width: 40px; height: 36px; }
}